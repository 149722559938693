import "../styles/globals.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";
import type { AppProps } from "next/app";

type WorkaroundAppProps = AppProps & {
  err: any;
};

function MyApp({ Component, pageProps, err }: WorkaroundAppProps) {
  return <Component {...pageProps} err={err} />;
}

export default MyApp;
